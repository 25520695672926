import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { DataPoint } from "./types";
import { useTheme } from '@material-ui/core/styles';

interface DataListProps {
    data: DataPoint[] | [];
    error: string | null;
}
function hexToRGBA(hex: string, alpha = 1) {
    const bigint = parseInt(hex.slice(1), 16); // Get the hex value without the '#'
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
const PrometheusGraph: React.FC<DataListProps> = ({
    data,
    error,
}) => {
    const theme = useTheme();
    const [size, setSize] = useState({
        width: window.innerWidth * 0.4,
        height: window.innerHeight * 0.4,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth * 0.4,
                height: window.innerHeight * 0.4,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (error)
        return <div>error occurred. Unable to load data</div>;
    if (!data) return <div>no data received.</div>;
    
    return (
        <Plot
            data={[
                {
                    x: data.map(row => row[0]),
                    y: data.map(row => row[1]),
                    type: 'scatter',
                    mode: 'lines',
                    fill: 'tonexty',
                    fillcolor: hexToRGBA(theme.palette.primary.main, 0.3),
                    line: {
                        color: theme.palette.primary.main,
                        width: 2
                        //marker: {color: '186c5c'}, // backstage green
                    }
                }
            ]}
            config={{ modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d', 'zoom2d', 'autoScale2d', 'zoomIn2d'], }}
            layout={{
                width: size.width,
                height: size.height,
                autosize: true,
                plot_bgcolor: 'transparent',
                yaxis: {
                    nticks: 5,
                    showgrid: false  // Disable y-axis grid lines
                },
                xaxis: {
                    //title: 'Time',
                    type: 'date',
                    nticks: 10,
                    showgrid: false  // Disable x-axis grid lines
                },
                font: {
                    family: theme.typography.body1.fontFamily,
                    size: 15,
                    color: 'black'
                }
            }}
        //,yaxis: {[0,maxRequests]}} } // Adapt y range to values
        />
    );
};

export default PrometheusGraph;
